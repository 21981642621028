/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getUser, loginUrlToken, logout } from 'src/actions';
// Example of user roles: ['GUEST', 'USER', 'ADMIN'];

function AuthGuard({ roles, children }) {
  const session = useSelector((state) => state.session);
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const paramToken = query.get('token');

  if (paramToken) {
    localStorage.setItem('token', paramToken);
  }
  const token = localStorage.getItem('token');

  useEffect(() => {
    console.log('AuthGuard');
    if (paramToken) {
      dispatch(getUser());
    } else if (!session.loggedIn || !session.user || !token) {
      dispatch(logout(history));
    }
  }, [dispatch, history, token, paramToken]);

  return <div>{children}</div>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.array.isRequired
};

export default AuthGuard;
