/* eslint-disable */
import React, { Suspense, useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getCollaborations, updateToastConfig } from 'src/actions';
import AuthGuard from 'src/components/AuthGuard';

import NavBar from './NavBar';
import TopBar from './TopBar';
import Toast from '../../components/Toast';

const navSetting = false;

function Dashboard() {
  const dispatch = useDispatch();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const collaborations = useSelector(
    (state) => state.collaboration.collaborations
  );
  const location = useLocation();
  const from = new URLSearchParams(location.search).get('from');

  const { toastConfig } = useSelector((state) => state.common);
  const handleCloseToast = () => {
    dispatch(updateToastConfig({ ...toastConfig, open: false }));
  };

  useEffect(() => {
    dispatch(getCollaborations(from));
  }, [dispatch, from]);

  return (
    <div>
      <div className="hidden sm:block">
        <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      </div>
      {navSetting && (
        <NavBar
          onMobileClose={() => setOpenNavBarMobile(false)}
          openMobile={openNavBarMobile}
          collaborations={collaborations}
        />
      )}
      <div className="flex min-h-screen">
        <div className="flex-grow max-w-full p-0 bg-white sm:pt-16 sm:bg-transparent">
          <AuthGuard roles={['ADMIN', 'DOCTOR', 'NURSE', 'PATIENT']}>
            <Suspense fallback={<LinearProgress />}>
              <Outlet />
            </Suspense>
          </AuthGuard>
        </div>
      </div>
      <Toast
        open={toastConfig.open}
        onClose={handleCloseToast}
        message={toastConfig.message}
        type={toastConfig.type}
      />
    </div>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
