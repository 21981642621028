/* eslint-disable max-len */
import { axiosInstance } from 'src/helpers/auth-header';
import stringFormatter from '../utils/stringFormatter';

const endpoints = {
  createUserFormData: stringFormatter.formatUrl('/api/user-form-data'),
  updateUserFormData: stringFormatter.formatUrl('/api/user-form-data'),
  saveAllUserFormData: stringFormatter.formatUrl('/api/user-form-data/save-all'),
  getUserFormDataByPrep: stringFormatter.formatUrl('/api/user-form-data/'),
  getUserFormDataByPatientId: stringFormatter.formatUrl('/api/user-form-data'),
  addReview: stringFormatter.formatUrl('/api/user-form-data/'),
  reviewTypeByProvider: stringFormatter.formatUrl('/api/user-form-data/provider/'),
  cancelUserFormDataTemplate: stringFormatter.formatUrl('/api/user-form-data/templates'),
};
const createUserFormData = (userFormsData) => axiosInstance.post(`${endpoints.createUserFormData}`, userFormsData);
const updateUserFormData = (userFormsData) => axiosInstance.put(`${endpoints.updateUserFormData}/${userFormsData.localId}`, userFormsData);
const saveAllUserFormData = (userFormsData) => axiosInstance.post(`${endpoints.saveAllUserFormData}`, userFormsData);
const getUserFormDataByPrep = (prepId, page = 0, limit = 25, keyword = '', filter = '') => axiosInstance.get(`${endpoints.getUserFormDataByPrep}${prepId}?page=${page}&limit=${limit}&keyword=${keyword}&filter=${filter}`);
const getUserFormDataByPatientId = (patientId) => axiosInstance.get(`${endpoints.getUserFormDataByPatientId}/${patientId}`);
const createReview = (review) => axiosInstance.post(endpoints.addReview, review);
const reviewTypeByProvider = (providerId) => axiosInstance.get(`${endpoints.reviewTypeByProvider}${providerId}`);
const cancelUserFormDataTemplate = (patientId) => axiosInstance.delete(`${endpoints.cancelUserFormDataTemplate}/${patientId}`);

export const userFormDataService = {
  createUserFormData,
  updateUserFormData,
  saveAllUserFormData,
  getUserFormDataByPrep,
  createReview,
  reviewTypeByProvider,
  cancelUserFormDataTemplate,
  getUserFormDataByPatientId
};

export default {};
