/* eslint-disable */
import { patientService } from 'src/services';
import { patientLogin, setIsVerify, verifyPatientFail } from 'src/actions/sessionActions';
import * as actionTypes from './actionTypes';

export const changePatientField = (name, value) => (dispatch) => {
  dispatch({ type: actionTypes.CHANGE_PATIENT_FIELD, name, value });
};

export const addPatient = () => (dispatch) => {
  dispatch({ type: actionTypes.TURN_ON_MODAL_ADD_PATIENT });
};

export const closeModal = () => (dispatch) => {
  dispatch({ type: actionTypes.TURN_OFF_MODAL_ADD_PATIENT });
};

export const getPatientsByPrep = (prepId) => (dispatch) => {
  patientService.getPatientsByPrep(prepId, 0, 50)
    .then(
      (response) => {
        const patients = response.data;
        dispatch({ type: actionTypes.GET_ALL_PATIENT_SUCCESS, patients });
      },
      () => {
        dispatch({ type: actionTypes.GET_ALL_PATIENT_FAILURE });
      }
    );
};

export const appendPatientsByPrep = (prepId, page, limit, filterDate) => (dispatch) => {
  patientService.getPatientsByPrep(prepId, page, limit, filterDate)
    .then(
      (response) => {
        const patients = response.data;
        dispatch({ type: actionTypes.GET_ALL_PATIENT_SUCCESS_APPEND, patients });
      },
      () => {
        dispatch({ type: actionTypes.GET_ALL_PATIENT_FAILURE });
      }
    );
};

export const emptyPatientsByPrep = () => (dispatch) => {
  dispatch({ type: actionTypes.EMPTY_PATIENT_LIST });
};

export const saving = () => (dispatch) => {
  dispatch({
    type: actionTypes.PATIENT_SAVING
  });
};

export const getPatientById = (id) => (dispatch) => {
  patientService.getPatientById(id)
    .then((data) => {
      dispatch({ type: actionTypes.GET_PATIENT_BY_ID_SUCCESS, patient: data.data });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_PATIENT_BY_ID_FAILURE,
        serverErrorMessage: error.response.data.message
      });
    });
};

export const createPatient = (patient, history, fn) => (dispatch) => {
  patientService.createPatient(patient)
    .then((response) => {
      const newPatient = response.data;
      if (fn) {
        fn(newPatient);
      }
      dispatch(getPatientById(newPatient.localId));
      dispatch({ type: actionTypes.ADD_PATIENT_SUCCESS });
      dispatch(getPatientsByPrep(patient.collaborationId));
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.ADD_PATIENT_FAILURE,
        serverErrorMessage: error.response.data.message
      });
      dispatch({ type: actionTypes.CHANGE_CREATE_PATIENT_NOTI_TYPE, data: 'error' });

      if (error.response.data.message === 'The patient phone number is not valid') {
        dispatch({ type: actionTypes.ENABLE_FORCE_CHANGE_BUTTON, data: true });
        dispatch({ type: actionTypes.CHANGE_CREATE_PATIENT_NOTI_TYPE, data: 'warning' });
      }
    }).finally(() => {
      history(patient.goTo || '/preps/appointments/a25ca1bd-7b62-42ed-9f3b-9e4dc35d9d71');
    });
};

export const createPatientUnauth = (patient, history) => (dispatch) => {
  patientService.createPatientUnauth(patient)
    .then((response) => {
      const patientLink = response.data;
      dispatch({ type: actionTypes.ADD_PATIENT_UNAUTH_SUCCESS });
      dispatch({ type: actionTypes.CHANGE_PATIENT_FORM_SUBMIT_SUCCESSFULLY_VALUE, value: true });
      localStorage.setItem('PATIENT_LINK', patientLink);
      history(patientLink);
    })
    .catch((error) => {
      dispatch({ type: actionTypes.CHANGE_CREATE_PATIENT_NOTI_TYPE, data: 'error' });
      dispatch({
        type: actionTypes.ADD_PATIENT_UNAUTH_FAILURE,
        serverErrorMessage: error.response.data.message
      });
    });
};

export const updatePatient = (patient, history, fn) => (dispatch) => {
  patientService.updatePatient(patient)
    .then(() => {
      dispatch({ type: actionTypes.UPDATE_PATIENT_SUCCESS });
      dispatch(getPatientsByPrep(patient.collaborationId));
      if (fn) {
        fn(patient);
      }
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE_PATIENT_FAILURE,
        serverErrorMessage: error.response.data.message
      });
    })
    .finally(() => {
      const editMode = patient.goTo.includes('edit-patient');
      history(editMode ? '/preps/appointments/a25ca1bd-7b62-42ed-9f3b-9e4dc35d9d71' : patient.goTo);
    });
};

export const updateFilterTodayPatient = (isToday) => (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_PATIENT_TODAY_FILTER, isToday });
};
export const updateFilterByDatePatient = (filterDate) => (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_PATIENT_DATE_FILTER, filterDate });
};

export const getVerifyQuestion = (id) => (dispatch) => {
  patientService.getVerifyQuestion(id)
    .then((data) => {
      dispatch({ type: actionTypes.GET_PATIENT_VERIFY_QUESTION_SUCCESS, question: data.data });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: actionTypes.GET_PATIENT_VERIFY_QUESTION_FAILURE,
        serverErrorMessage: error.response.data.message
      });
    });
};

export const submitVerifyQuestion = (patientId, questionId, answer) => (dispatch) => {
  patientService.submitVerifyAnswer(patientId, questionId, answer)
    .then((data) => {
      if (data.data.result === true) {
        dispatch(patientLogin(patientId));
        dispatch(setIsVerify(true));
      } else {
        dispatch(verifyPatientFail);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const selectedPatientIdAction = (selectedPatientId) => (dispatch) => {
  dispatch({ type: actionTypes.SELECTED_PATIENT_ID, selectedPatientId });
};

export const selectedPatientAction = (patient) => (dispatch) => {
  dispatch({ type: actionTypes.SELECTED_PATIENT, patient });
};

export const editPatient = (patient) => (dispatch) => {
  dispatch({ type: actionTypes.EDIT_PATIENT, patient });
};

export const editPatientById = (patientId) => (dispatch) => {
  patientService.getPatientById(patientId)
    .then((response) => {
      const patient = response.data;
      dispatch({ type: actionTypes.EDIT_PATIENT, patient });
    })
    .catch((error) => {
      console.log(error);
    });
};

// export const enableForceChangeButton = (value) => (dispatch) => {
//   dispatch({ type: actionTypes.ENABLE_FORCE_CHANGE_BUTTON, value });
// };

export const changeForceCreateValue = (value) => (dispatch) => {
  dispatch({ type: actionTypes.CHANGE_FORCE_CHANGE_VALUE, data: value });
};

export const changeNewPatientProcedureTime = (field, time) => (dispatch) => {
  dispatch({
    type: actionTypes.CHANGE_PROCEDURE_TIME,
    data: {
      field,
      value: time
    }
  });
};

export const resetHasServerErrorValue = (value) => (dispatch) => {
  dispatch({ type: actionTypes.RESET_HAS_SERVER_ERROR_VALUE, value });
};

export const updatePatientUnauth = (patient, history) => (dispatch) => {
  patientService.updatePatientUnauth(patient)
    .then((response) => {
      const patientLink = response.data;
      dispatch({ type: actionTypes.ADD_PATIENT_UNAUTH_SUCCESS });
      dispatch({ type: actionTypes.CHANGE_PATIENT_FORM_SUBMIT_SUCCESSFULLY_VALUE, value: true });
      localStorage.setItem('PATIENT_LINK', patientLink);
      history(patientLink);
    })
    .catch((error) => {
      dispatch({ type: actionTypes.CHANGE_CREATE_PATIENT_NOTI_TYPE, data: 'error' });
      dispatch({
        type: actionTypes.ADD_PATIENT_UNAUTH_FAILURE,
        serverErrorMessage: error.response.data.message
      });
    });
};
