/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash-es/collection';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import {
  AppBar,
  Badge,
  IconButton,
  Toolbar,
  Hidden,
  Button,
  TextField,
  Menu,
  MenuItem
} from '@mui/material';
import {
  Menu as MenuIcon,
  NotificationsOutlined as NotificationsIcon,
  Visibility as VisibilityIcon,
  AccountCircle as AccountCircleIcon,
  CalendarToday as CalendarTodayIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  AccountBox as AccountBoxIcon,
  ExitToApp as ExitToAppIcon
} from '@mui/icons-material';
import NotificationsPopover from 'src/components/NotificationsPopover';
import * as actionTypes from 'src/actions/actionTypes';
import { notificationsService } from '../../services';
import style from './TopBar.style';
import './TopBar.css';
import { prepMenu } from '../../constant/prepMenu';
import { updateShowPreview } from '../../actions';
import { NavigationBar } from '../../components/NavigationBar';
import { isAdministrators } from '../../helpers/permission-check';

const useStyles = makeStyles(style);

function TopBar({ onOpenNavBarMobile, className, ...rest }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const notificationsRef = useRef(null);
  const notifications = useSelector((state) =>
    state.notifications && state.notifications.notifications
      ? state.notifications.notifications
      : []
  );
  const [openNotifications, setOpenNotifications] = useState(false);
  const businessId = useSelector((state) =>
    state.session && state.session.businessId ? state.session.businessId : ''
  );
  const { collaborations } = useSelector((state) => state.collaboration);
  const { showPreview } = useSelector((state) => state.common);
  const { isDoctor, email, userType } = useSelector((state) => state.session);

  const [prepItem, setPrepItem] = useState('');
  const [selectedPrep, setSelectedPrep] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes(prepMenu.Form)) {
      setPrepItem(prepMenu.Form);
      if (!isDoctor) {
        window.location.replace('/');
      }
    }
    if (currentUrl.includes(prepMenu.Patients)) {
      setPrepItem(prepMenu.Patients);
    }
    if (currentUrl.includes(prepMenu.ReferProviders)) {
      setPrepItem(prepMenu.ReferProviders);
    }
    if (currentUrl.includes(prepMenu.Template)) {
      setPrepItem(prepMenu.Template);
      if (!isDoctor) {
        window.location.replace('/');
      }
    }
    if (currentUrl.includes(prepMenu.AllPrep)) {
      if (!isDoctor) {
        if (!isEmpty(collaborations)) {
          const generalPrep = find(
            collaborations,
            (col) => col.name === 'General Registration'
          );
          // eslint-disable-next-line max-len
          window.location.replace(
            prepMenu.Patients + generalPrep?.localId ||
              collaborations[0].localId
          );
        }
      }
    }
    const selectedPrepObj = find(collaborations, (c) =>
      currentUrl.includes(c.localId)
    );
    if (!isEmpty(selectedPrepObj)) {
      setSelectedPrep(selectedPrepObj.localId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaborations]);

  useEffect(() => {
    if (selectedPrep && prepItem) {
      const currentUrl = window.location.href;
      if (currentUrl.includes(prepItem) && currentUrl.includes(selectedPrep)) {
        return;
      }
      window.location.replace(`${prepItem}${selectedPrep}`);
    }
  }, [selectedPrep, prepItem]);

  const collaborationSelectData = collaborations.map((c) => ({
    localId: c.localId,
    name: c.name
  }));

  const checkShowPatientListButton = () => {
    let isShow = false;
    const detailMode = window.location.href.includes('patients');
    if (isDoctor === 0 && !detailMode) {
      isShow = true;
    }
    return isShow;
  };

  const clickListPatient = () => {
    const generalRegistrationTemplate = find(
      collaborations,
      (col) => col.name === 'General Registration'
    );
    if (generalRegistrationTemplate && selectedPrep === '') {
      window.location.replace(
        `/preps/patients/${generalRegistrationTemplate.localId}`
      );
    } else {
      window.location.replace(`/preps/patients/${selectedPrep}`);
    }
  };

  const totalUnread = notifications.filter((n) => !n.read).length;

  const handleNotificationsOpen = () => {
    notifications
      .filter((n) => !n.read)
      .forEach((n) => {
        notificationsService
          .updateNotification({
            local_id: n.id,
            read: true
          })
          .then(
            () => {
              setTimeout(() => {
                const foundIndex = notifications.findIndex(
                  (x) => x.id === n.id
                );
                notifications[foundIndex].read = true;

                dispatch({
                  type: actionTypes.NOTIFICATION_RECEIVED_ALL,
                  notifications
                });
              }, 1000);
            },
            // eslint-disable-next-line no-console
            (error) => {
              console.log('Error --------------- ', error);
            }
          );
      });

    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  useEffect(() => {
    let mounted = true;

    const fetchNotifications = () => {
      notificationsService.getNotificationsByBusiness(businessId).then(
        (response) => {
          const notificationItems = response.data.map((n) => ({
            id: n.local_id,
            title: n.content,
            type: 'message',
            created_at: moment(n.notification_date_time),
            data: {
              patient_id: n.from,
              prep_id: n.prep_id
            },
            read: n.read
          }));

          dispatch({
            type: actionTypes.NOTIFICATION_RECEIVED_ALL,
            notifications: notificationItems
          });
        },
        // eslint-disable-next-line no-console
        (error) => {
          console.log('Error --------------- ', error);
        }
      );
    };

    fetchNotifications();

    return () => {
      mounted = false;
    };
  }, [dispatch, businessId]);

  useEffect(() => {
    let mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const handleClickAccountIcon = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAccountMenu = () => {
    setAnchorEl(null);
  };

  const handleClickPreview = () => {
    dispatch(updateShowPreview(!showPreview));
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar className="flex items-center justify-between">
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/" className="homepage-icon">
          <img
            className={classes.topBarLogo}
            alt="Logo"
            src="/images/logos/cp.png"
          />
        </RouterLink>
        <NavigationBar />
        {/* {!isEmpty(collaborationSelectData) && !!isDoctor && (
          <div className="select-collaboration">
            <TextField
              fullWidth
              name="collaboration"
              select
              SelectProps={{ native: true }}
              value={selectedPrep}
              onChange={event => setSelectedPrep(event.target.value)}
              variant="outlined"
              size="small"
            >
              <option disabled />
              {collaborationSelectData?.map(c => (
                <option key={c.localId} value={c.localId}>
                  {c.name}
                </option>
              ))}
            </TextField>
          </div>
        )} */}

        <IconButton
          className={classes.notificationsButton}
          color="inherit"
          onClick={handleClickPreview}
        >
          <VisibilityIcon />
        </IconButton>
        {/* {!!isDoctor && (
        <IconButton
          className={classes.notificationsButton}
          color='inherit'
          onClick={() => {
            window.location.href = '/preps/create';
          }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
        )} */}
        {/* <IconButton
          className={classes.notificationsButton}
          color="inherit"
          onClick={() => {
            window.location.href = '/calendar';
          }}
        >
          <CalendarTodayIcon />
        </IconButton> */}
        <IconButton
          className={classes.notificationsButton}
          color="inherit"
          onClick={handleNotificationsOpen}
          ref={notificationsRef}
        >
          <Badge
            badgeContent={totalUnread}
            classes={{ badge: classes.notificationsBadge }}
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton
          className={classes.notificationsButton}
          color="inherit"
          onClick={handleClickAccountIcon}
        >
          <AccountCircleIcon />
        </IconButton>
        <Menu
          id="account-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseAccountMenu}
        >
          <MenuItem
            onClick={() => {
              window.location.href = '/profile/account';
            }}
          >
            <AccountBoxIcon /> Profile
          </MenuItem>
          {isAdministrators(userType) && (
            <MenuItem
              onClick={() => {
                window.location.href = '/add-user';
              }}
            >
              <AccountBoxIcon /> Manage Users
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              window.location.href = '/signout';
            }}
          >
            <ExitToAppIcon /> Logout
          </MenuItem>
        </Menu>
        <NotificationsPopover
          anchorEl={notificationsRef.current}
          notifications={notifications}
          onClose={handleNotificationsClose}
          open={openNotifications}
        />
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
